import React, { useEffect, useState } from 'react';
import {
  DeepLinkingConfig,
  DeepLinkingProvider,
} from 'assets/core/deep-linking';
import { RootNavigation } from './modules/navigation/RootNavigation';
import { NavigationContainer } from './modules/common/NavigationContainer';
import { ApiConfig } from 'assets/core/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from './enums/storage-keys';
import AppConfigService from './modules/api/AppConfigService';
import { LoadingIndicator } from 'assets/components/loading-indicator';

ApiConfig.setBaseApiConfig({
  getAccessToken: async () =>
    await AsyncStorage.getItem(StorageKeys.AccessToken),
  setAccessToken: async (token: string) =>
    await AsyncStorage.setItem(StorageKeys.AccessToken, token),
});

export default function App() {
  const [deepLinkingConfig, setDeepLinkingConfig] =
    useState<DeepLinkingConfig>();

  useEffect(() => {
    (async () => {
      const dlc = await AppConfigService.getDeepLinkingConfig();
      setDeepLinkingConfig(dlc);
    })();
  }, []);

  return deepLinkingConfig ? (
    <NavigationContainer>
      <DeepLinkingProvider
        handleAutomaticRedirection={false}
        deepLinkingConfig={deepLinkingConfig}
      >
        <RootNavigation />
      </DeepLinkingProvider>
    </NavigationContainer>
  ) : (
    <LoadingIndicator />
  );
}
