import React, { useEffect, useState } from 'react';
import { View, Text, Button } from 'react-native';
import { useDeepLinkingContext } from 'assets/core/deep-linking';
import { useTheme } from 'assets/theme';
import * as Linking from 'expo-linking';
import axios from 'axios';

export const DocumentsScreen = () => {
  const [redirectionFiled, setRedirectionFailed] = useState(false);
  const theme = useTheme();
  const { currentUrl, launchApp } = useDeepLinkingContext();
  const [fileURL, stFileUrl] = useState<string>();

  useEffect(() => {
    setTimeout(() => setRedirectionFailed(true), 2000);
    // testDocumentStreamingView();
  }, []);

  const testDocumentStreamingView = () => {
    axios(
      `https://api.lumistry.com/vuca/api/v2/medication_guide/nHwhfhmUWistaL2QU2w3K3?locale=en-US&ndc=532170371`,
      {
        method: 'GET',
        responseType: 'blob', //Force to receive data in a Blob Format
      },
    )
      .then((response) => {
        console.log(response);
        const file = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(file);
        stFileUrl(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRedirection = () => {
    if (currentUrl && currentUrl !== '') {
      const { path, queryParams } = Linking.parse(currentUrl);
      const url = [
        path ?? '/',
        queryParams &&
          new URLSearchParams(queryParams as Record<string, string>).toString(),
      ]
        .filter((x) => x)
        .join('?');

      console.log(url);
      launchApp(url ?? '/');
    }
  };

  useEffect(handleRedirection, [currentUrl]);

  return (
    <View>
      <Text>Redirecting to: {currentUrl}</Text>

      {!redirectionFiled && (
        <Text>Please wait. Your page is being redirected...</Text>
      )}
      {redirectionFiled && (
        <>
          <Text>
            Redirection failed. Please try again by clicking on the button or go
            back and try again.
          </Text>
          <View style={{ maxWidth: 250, padding: theme.getSpacing(2) }}>
            <Button title="Click" onPress={handleRedirection} />
          </View>
        </>
      )}

      {fileURL && (
        <object
          data={fileURL}
          type="application/pdf"
          width="800"
          height="400"
        />
      )}
    </View>
  );
};
