import {TranslationMapping} from "../localization";

const mapping: TranslationMapping = {
  "signup": "Create an account",
  "login": "Login",
  "username": "Username",
  "password": "Password",
  "dashboard": "Home",
  "medications": "Medications",
  "messages": "Messages",
  "profile": "Profile",
  "get-care": "Get Care",
  "anonymous-refill": "Refill without an account",
  "apple-signup": "Sign up with Apple",
  "apple-continue": "Continue with Apple",
  "apple-login": "Apple login",
  "lets-get-to-know-you": "Let's get to know you",
  "lets-get-to-know-you-details": "Complete your new patient form so we have all your information on-file. This ensures we can fill your prescription correctly.",
  "welcome": "Welcome",
  "logout": "Logout",
  "front": "Front",
  "back": "Back",
  "otc-medications": "Over-The-Counter Medications",
  "next": "Next",
  "scan-barcode": "Scan Barcode",
  "scanned": "Scanned",
  "save": "Save",
  "forgot-password": "Forgot password?",
  "back-to-login": "Back to login",
  "camera-access-denied": "Unable to access camera, please go to your app settings and grant camera access.",
  "username-is-required": "Username is required",
  "password-is-required": "Password is required",
  "view-full-profile": "View Full Profile",
  "insurance-card": "Insurance Card",
  "barcode-scanner": "Barcode Scanner",
  "english": "English",
  "spanish": "Spanish",
  "french": "French",
  "yes": "Yes",
  "no": "No",
  "continue": "Continue",
  "first-name": "First name *",
  "first-name-is-required": "First Name is required",
  "last-name": "Last name *",
  "last-name-is-required": "Last Name is required",
  "email-short": "Email",
  "email": "Email address",
  "email-is-required": "Email is required",
  "email-continue": "Continue with email",
  "mobile-number": "Mobile number *",
  "mobile-number-is-required": "Mobile Number is required",
  "birth-date": "Date of birth (mm/dd/yyyy) *",
  "coming-soon": "Planned for MVP, not yet available",
  "section-on-the-roadmap": "{{section}} on the roadmap, not yet available",
  "bottle-cap-preference": "Bottle cap preference",
  "allergies-conditions-caregivers": "Allergies, conditions, and caregivers",
  "insurance-id-card": "Insurance and I.D. Card",
  "patient-forms": "Patient Forms",
  "communication-preferences": "Communications Preferences",
  "sign-out": "Sign out",
  "didnt-receive-text-message": "Didn't receive the text message?",
  "phone-number-is-sms-capable": "Please make sure that the phone number you entered is SMS-capable or resend code.",
  "standard-text-messages-apply": "Standard text messaging rates may apply\ndepending on your service provider.",
  "birth-date-is-required": "Birth Date is required",
  "lets-get-started": "Let's get started by creating an account.",
  "front-of-card": "Front of card",
  "back-of-card": "Back of card",
  "add-front-of-card": "Add front of card",
  "add-back-of-card": "Add Back of card",
  "cancel": "Cancel",
  "cancellations": "Cancellations",
  "retake": "Retake",
  "use-photo": "Use Photo",
  "password-must-include": "Password must include:",
  "at-least-8-characters": "At least 8 characters",
  "at-least-1-number": "At least 1 number",
  "at-least-1-capital-letter": "At least 1 capital letter",
  "at-least-1-lowercase-letter": "At least 1 lowercase letter",
  "at-least-1-special-character": "At least 1 special character",
  "privacy-policy-error": "Please read the privacy policy before agreeing",
  "privacy-policy-agree": "I have read and agree to the Privacy Policy",
  "privacy-policy-email-signup": "Sign up for email from my pharmacy",
  "pharmacy-privacy-policy": "Pharmacy Privacy Policy",
  "terms-and-conditions": "Software Provider Terms & Conditions",
  "terms-of-service": "Terms and Conditions",
  "terms-of-service-error": "Please read the terms of service before agreeing",
  "terms-of-service-agree": "I have read and agree to the Terms and Conditions",
  "terms-of-service-email-signup": "Sign up for email from software provider",
  "and": "and",
  "submit": "Submit",
  "update-personal-info": "Update Personal Info",
  "name-phone-email": "Name, phone, email, etc.",
  "2-of-2-agreements": "2 of 2 agreements",
  "medical-info-step-3": "Step 3 of 8 / Add your medical information",
  "password-entry-does-not-meet-criteria": "Password entry does not meet criteria",
  "password-entries-do-not-match": "Password entries do not match",
  "create-your-account": "Create Your Account",
  "please-enter-your-new-password": "Please enter your new password",
  "email-is-not-valid": "Email is not valid",
  "phone-is-not-valid": "Phone number is not valid",
  "create-account": "Create account",
  "confirm-password": "Confirm Password",
  "patient-info-step": "Step x of y / Confirm your information",
  "communications-language": "Communications Language",
  "prescriptions-communications": "Prescription Communications: How would you like to be notified when your prescriptions are ready?",
  "automated-voice-call": "Automated Voice Call",
  "text-message": "Text message",
  "app-notifications": "App Notifications",
  "all-other-pharmacy-communications": "All other pharmacy communications",
  "same-time-preferences": "Would you like your prescriptions to be ready together at the same time each month?",
  "additional-information": "Additional information",
  "visually-impaired": "Visually Impaired",
  "hearing-impaired": "Hearing Impaired",
  "easy-open-bottle-caps": "Easy-open bottle caps",
  "personal-info-step-2": "Step 2 of 7 / Add your personal information",
  "country": "Country",
  "country-is-required": "Country is required",
  "state": "State",
  "state-is-required": "State is required",
  "city": "City",
  "city-is-required": "City is required",
  "street-address": "Street Address",
  "street-address-is-required": "Street Address is required",
  "street-address-line-2": "Street Address Line 2",
  "zip-code": "Zip Code",
  "zip-code-is-required": "Zip Code is required",
  "gender": "Gender (as it appears on insurance)*",
  "gender-is-required": "Gender is required",
  "male": "Male",
  "female": "Female",
  "verification-code-resent": "Verification code resent",
  "please-enter-code": "Please enter the code we sent to",
  "didnt-receive-email": "Didn't receive the email? ",
  "please-check-spam-folder": "Please check your spam folder or resend code",
  "resend-code": "Resend Code",
  "reset-password": "Reset password",
  "reset-link-sent": "If the email is associated with an active account, you should receive a reset link.",
  "please-enter-email": "Please enter the email address\nassociated with your account",
  "how-would-you-like-to-receive-reset-link": "How would you like to receive the reset link?",
  "using-the-phone-number-on-file": "Using the phone number on file",
  "send-reset-link": "Send reset link",
  "create-new-password": "Create new password",
  "for-security-reasons": "For security reasons, your password expires every 90 days, Please update your password.",
  "update-password": "Update password",
  "password-expiring-soon": "Password expiring soon",
  "password-expire-in-7-days": "Your password will expire in 7 days.",
  "update-password-now": "Do you want to update your password now?",
  "new-password": "New password *",
  "confirm-new-password": "Confirm password *",
  "confirm-update-password": "Confirm new password *",
  "change-password": "Change password",
  "login-with-email": "Login with email",
  "login-enter-email": "Enter email",
  "go-to-login": "Go to login",
  "return-to-login": "Return to login",
  "passwords-must-match": "Passwords do not match",
  "dismiss": "Dismiss",
  "load-more": "Load More",
  "loading": "Loading",
  "open-map": "Open Map",
  "input-field": "Input field",
  "text-field": "Text field",
  "show-hide-password": "Show/Hide Password",
  "search": "Search",
  "select": "Select",
  "use": "Use",
  "no-options": "No options",
  "email-or-password-incorrect": "Invalid email or password entered, please try again.",
  "unable-to-complete-registration": "Unable to complete registration. Please try again.",
  "unable-to-update-account": "Unable to update account. Please try again.",
  "unable-to-complete-verification": "Unable to complete verification. Please try again.",
  "unable-to-resend-verification-code": "Unable to resend verification code. Please try again.",
  "error-submitting": "Error submitting. Please try again.",
  "unable-to-send-reset-link": "Unable to send reset link. Please try again.",
  "unable-to-reset-password": "Unable to reset password. Please try again.",
  "store-selector-change": "Change",
  "store-selector-search-address": "Zip code",
  "store-selector-change-store": "Change Store",
  "store-selector-your-store": "Your Store",
  "store-selector-nearby-store": "Nearby Stores",
  "store-selector-close": "Close",
  "store-selector-select": "Select",
  "store-selector-no-stores-error": "Unable to find pharmacy location",
  "feedback": "Feedback",
  "feedback-give-feedback": "Give Feedback",
  "feedback-give-your-feedback": "Give your feedback",
  "feedback-please-enter-feedback": "Please enter feedback",
  "feedback-how-to-improve-app": "How can we improve our app?",
  "feedback-give-feedback-about-pharmacy": "Give us feedback about our pharmacy",
  "feedback-sent": "Feedback sent!",
  "feedback-thank-you": "Thank you for your feedback!",
  "feedback-what-type-of-feedback": "What type of feedback are you leaving today?",
  "feedback-target-is-required": "Feedback target is required.",
  "feedback-feedback": "Enter additional information",
  "feedback-feedback-is-required": "Feedback info is required.",
  "feedback-allowContact": "I agree to you contacting me for further clarifications.",
  "feedback-thanks-message": "Thanks for your feedback!",
  "feedback-reach-you-message": "We will reach out to you directly if we have questions.",
  "feedback-back-to-profile": "Back to profile",
  "feedback-error-occurred": "An error has occurred.",
  "feedback-contact-info": "Contact info",
  "feedback-edit": "Edit",
  "feedback-email-address": "Email address",
  "feedback-phone-number": "Phone number",
  "feedback-app": "App",
  "feedback-location": "Store",
  "refill-card-medication": "Medication",
  "refill-card-due-date": "Refill due",
  "prescription-card-day-supply": "day supply",
  "prescription-card-filled-on": "Filled on:",
  "prescription-card-last-filled": "Last filled:",
  "prescription-card-last-ordered": "Last Ordered:",
  "prescription-card-refill-due": "Refill due:",
  "remove": "Remove",
  "prescription-card-refills": "Refills remaining",
  "prescription-card-dob": "DOB",
  "medications-fill-your-prescription": "Fill Your Prescription",
  "medications-ready-to-be-filled": "Ready to be filled",
  "medications-other-upcoming-refills": "Other upcoming refills",
  "insurance-card-upload-error": "Unable to upload card. Please try again.",
  "camera-mask-label": "Center the front of your card in the frame",
  "primary-insurance-add-your-information": "Add your primary insurance card",
  "secondary-insurance-add-your-information": "Add your secondary insurance card",
  "photoId": "Add your photo ID card",
  "photo-take-card-picture" : "Take a picture or upload an image of your photo Id card",
  "insurance-take-card-picture": "Take a picture or upload an image of both sides of your insurance card",
  "take-photo": "Take Photo",
  "choose-photo": "Choose Photo",
  "insurance-bring-to-store": "I will bring it to the store",
  "google-signup": "Sign up with Google",
  "google-continue": "Continue with Google",
  "google-login": "Google login",
  "google-login-failed": "Google Login Failed",
  "date-is-not-valid": "Date is not valid",
  "verify-your-email": "Verify your email address",
  "verify-your-phone": "Verify your phone number",
  "please-enter-your-information": "Please enter your information",
  "email-address": "Email address",
  "patient-stores": "Find your store",
  "user-not-found": "Unknown error. Please make sure that your email address and the pharmacy you’ve selected are correct.",
  "invalid-confirmation-code": "Invalid reset link, click here to get a new one",
  "contact-support": "Unknown error, please try again. If problem persists, please contact support.",
  "many-failed-attempts": "Too many failed attempts, please try again in an hour",
  "not-authorized": "Invalid reset link, click here to get a new one",
  "done": "Done",
  "do-this-later": "Do this later",
  "entered-prescription-manually": "Entered Manually",
  "your-prescriptions": "Your prescriptions",
  "add": "Add",
  "drug-name": "Drug name",
  "drug-name-is-required": "Drug name is required",
  "rx-number": "Rx number",
  "add-your-prescriptions": "Add your prescriptions",
  "retrieve-your-prescriptions": "Retrieve your prescriptions",
  "other-meds": "I have other meds not on this list",
  "try-again": "Try again",
  "step": "Step",
  "rx-number-is-required": "Rx number is required",
  "change": "Change",
  "location": "Location",
  "locations": "Locations",
  "patient-dob": "Patient DOB (mm/dd/yyyy) *",
  "patient-dob-is-required": "Date of birth is required",
  "patient-last-name-is-required": "Patient last name is required",
  "patient-last-name": "Patient last name *",
  "we-found-your-medications-alert-title": "We found your medications",
  "unable-to-locate-your-prescription-alert-title": "Unable to locate your prescription list",
  "unable-to-locate-your-prescription-alert-description": "Please confirm that the information below matches what's on your prescription label",
  "prescription-information": "Prescription information",
  "integrated-prescription-flow-description": "Enter any recent prescription number to connect your prescription list with the specific store you selected",
  "forms": "Forms",
  "tasks": "Tasks",
  "patients": "Patients",
  "refill-submissions": "Refill Submissions",
  "refill-submissions-header": "Refill submissions",
  "user": "User",
  "users": "Users",
  "new-user": "New user",
  "user-details": "User Details",
  "title": "Title",
  "role": "Role",
  "last-active": "Last Active",
  "registered": "Registered",
  "activity": "Activity",
  "show": "Show",
  "edit": "Edit",
  "view-activity-logs": "View activity logs",
  "resend-invite": "Resend invite",
  "delete": "Delete",
  "delete-confirmation": "Are you sure you want to delete this?",
  "cannot-undo-action": "You cannot undo this action.",
  "email-is-invalid": "Email is invalid",
  "passwords-do-not-match": "Password entries do not match",
  "password-does-not-meet-criteria": "Password entry does not meet criteria",
  "follow-reset-password-instructions": "Please follow the instructions to reset your password.",
  "pharmacy-reset-link-sent": "An email has been sent to ",
  "need-help": "Need Help? (877) 959-7550",
  "set-your-preferences": "Step {{currentStep}} of {{totalSteps}} / Set your preferences",
  "find-your-store": "Find your store",
  "confirm-your-store": "Step {{currentStep}} of {{totalSteps}} / Confirm your store",
  "prescribed-for": "Prescribed for",
  "last-filled-on": "Last filled on",
  "last-ordered-on": "Last ordered on",
  "auto-refills": "Auto-refills",
  "auto-refill": "Auto-refill",
  "refills-left": "Refills left",
  "quantity-dispensed": "Quantity dispensed",
  "days-supply": "Days supply",
  "directions": "Directions",
  "questions-about-medication": "Questions about this medication?",
  "ask-your-pharmacist": "Ask your pharmacist",
  "message": "Message",
  "refill": "Refill",
  "child": "Child",
  "spouse": "Spouse",
  "parent": "Parent",
  "grandparent": "Grandparent",
  "grandchild": "Grandchild",
  "pet": "Pet",
  "partner": "Partner",
  "other": "Other person under my care",
  "resources": "RESOURCES",
  "medication-guides": "Medication Guides",
  "how-to-take": "How to take this medication",
  "patient-education-sheets": "Patient Education Sheets",
  "side-effects-interactions": "Side effects, drug interactions",
  "prescription-written": "Rx date written",
  "prescription-by": "Prescription by",
  "call-prescriber": "Prescriber: ",
  "link-expired": "The link has expired or is invalid, you'll need a new one.",
  "reset-unsuccessful": "Reset unsuccessful",
  "order-submitted": "Your order is submitted!",
  "order": "Order",
  "picking-up-at": "Picking up at",
  "ready-today": "Ready today between",
  "notify-you": "We'll be sure to notify you",
  "hi": "Hi, {{firstName}}.",
  "create-your-password": "Please create your password",
  "i-have-read-and-agree": "I have read and agree to the",
  "pharmacy-terms-and-conditions": "Terms and Conditions",
  "privacy-policy": "Privacy Statement",
  "terms-and-conditions-required": "Terms and Conditions agreement is required",
  "privacy-policy-required": "Privacy Statement agreement is required",
  "new-patient": "New patient",
  "i-am-a-new-patient": "I am a new patient",
  "continuation-disclaimer": "By tapping Continue with Apple, Google, or email, you agree to this Pharmacy's",
  "patient-details": "Patient Details",
  "date-of-birth": "Date of birth",
  "date-of-birth-short": "DOB",
  "phone-number": "Phone number",
  "gender-text": "Gender",
  "language": "Language",
  "conditions": "Conditions",
  "easy-open-bottle": "Easy-open bottle",
  "med-sync": "Med Sync",
  "photo-id": "Photo ID",
  "image": "Image",
  "notification": "Notification",
  "app-notification": "App notification",
  "list": "List",
  "providers": "Providers",
  "puc-permission-access-to-records": "I confirm that {{firstName}} {{lastName}} has permission to access my records and manage health digitally and that I can revoke access at any time.",
  "if-you-authorize-person": "If you authorize this request, then this person can:",
  "access-validity": "This access is valid until you revoke it.",
  "revoke-access-in-settings": "You can revoke access by going to your Medical Info in Account Settings",
  "review-request": "Review request...",
  "authorize": "Authorize",
  "decline": "Decline",
  "notes": "Notes",
  "internal-use-only": "Internal use only",
  "to-get-started-choose": "To get you started,\nplease choose one of the following:",
  "face-id": "Face ID",
  "medical-equipment-immunization": "Immunizations, Durable Medical Equipment, Compounting, Patient Consultation, COVID-19 Vaccine",
  "services-offered": "Services offered",
  "en-es-fr": "English, Spanish, French",
  "languages-spoken": "Languages spoken at this location",
  "holiday-hours-vary": "Holiday hours may vary.",
  "open-hours": "Open today: {{hours}}",
  "open-today": "Open today 24 hours.",
  "store-hours": "Store Hours",
  "set-my-store": "Set as my store",
  "manage-prescriptions": "Manage your prescriptions digitally",
  "pick-up-prescriptions": "Pick up your prescriptions",
  "discuss-care": "Discuss your care with a {{pharmacyName}} team member",
  "manage-health": "Manage your health and clinical service appointments",
  "upload-csv": "Upload csv",
  "add-patient": "Add Patient",
  "revoke-access": "Revoke access",
  "authorized-on": "Authorized on {{authorizedDate}}",
  "request-status": "Pending: submitted on {{submittedDate}}",
  "authorized-caregivers": "authorized caregivers",
  "personal-info": "Personal Info",
  "will-pickup-prescription-today": "Will you be picking your prescription up today?",
  "field-required": "This field is required",
  "will-pickup-prescription-urgently": "Do you need to pickup your prescription urgently?",
  "order-details": "Order Details",
  "method": "Method",
  "note-for-pharmacy": "Note for the pharmacy (optional)",
  "note-for-pharmacy-not-optional": "Note for the pharmacy",
  "review": "Review",
  "no-allergies": "No known allergies",
  "insurance-is-required": "Insurance is required",
  "insurance-change-last-refill": "Has your insurance changed since your last refill? *",
  "prescription-ready-each-month": "Would you like all of your prescriptions to be ready together at the same time each month?",
  "prescription-find-server-error": "Internal Server Error during finding your medications",
  "prescriptions-is-required": "Prescriptions is required",
  "over-the-counter-medications": "Over-the-counter medications",
  "please-review-messages-and-proceed": "Please review the messages below and let us know how you would like to proceed.",
  "how-to-proceed": "How to proceed",
  "enter-patient-email": "Enter patient email address",
  "add-patient-request-submit": "Add patient request submitted",
  "provide-an-email": "Please provide the email address that {{firstName}} {{lastName}} uses for {{firstName}}'s account with {{pharmacyName}}",
  "request-sent": "Request sent to {{firstName}} {{lastName}}",
  "approval-needed": "Approval is needed from this person within 14 days",
  "person-has-an-account": "If this person has an account at {{pharmacyName}}, then they will receive your request",
  "add-another-person": "Add another person",
  "add-another": "Add another",
  "go-home": "Go home",
  "about-this-app": "About this app",
  "copyright-lumistry": "Copyright Lumistry",
  "all-rights-reserved": "All Rights Reserved",
  "version": "Version",
  "enter-patient-information": "Enter patient information",
  "allow-pharmacy-contact-prescriber": "Allow pharmacy to contact prescriber",
  "continue-with-selected-items": "Continue with the selected items",
  "go-back": "Go back",
  "go-back-update-order": "Go back to update my order",
  "thank-you-for-choosing": "Thank you for choosing",
  "return-to-order-details": "Return to order details",
  "appointments": "Appointments",
  "add-medications": "Add medications",
  "add-meds-text": "You can order prescription refills easily and manage medications by adding them now, or skip this step to do it later",
  "prescriptions-at-file": "Prescriptions are on file at Cameron’s Pharmacy",
  "request-to-transfer": "Request to transfer prescriptions from a different pharmacy",
  "skip-this-step": "Skip this step",
  "do-this-medications-tab": "You can do this in the Medications tab",
  "account": "Account",
  "add-people-under-care": "Add people under your care",
  "add-people-under-care-message-pt-1": "You can find the person you want to add by providing their information on the next page",
  "add-people-under-care-message-pt-2": "If the person you are adding is 18+ years old, then you will also need to provide the email address that they use for their account at",
  "add-person": "Add a person",
  "unable-to-process-items": "Unable to process one or more items",
  "we-will-contact-prescriber": "We will contact your prescriber for authorization on the following prescriptions. ",
  "please-review-the-messages-below": "Please review the messages below and let us know how you would like to proceed.",
  "ready-to-be-filled": "Ready to be filled",
  "close": "Close",
  "closed": "Closed",
  "greeting": "Good Morning",
  "your-password-has-been-successfully-changed": "Your password has been successfully changed",
  "other-details": "Other details",
  "store-info": "Store info",
  "account-setup": "Account Setup",
  "other-information": "Other information",
  "insurance": "Insurance",
  "revoke": "Revoke",
  "first-name-is-not-valid": "First name is not valid",
  "login-attempts-exceeded": "Login attempts exceeded",
  "max-attempts-exceeded": "Maximum attempts exceeded",
  "account-locked-reason": "Your account has been locked because you have reached the maximum number of invalid sign-in attempts allowed.",
  "security-reason": "For security purposes, please choose an option below to change your password and recover your account.",
  "send-me-email": "Send me an email",
  "send-me-sms": "Send me a text message",
  "last-name-is-not-valid": "Last name is not valid",
  "update-weak-password": "Your password does not meet current requirements. For the security and protection of your account, please create a new one.",
  "revoke-access-for-authorized-caregiver": "Revoking access means that {{firstName}} {{lastName}} is no longer one of your authorized caregivers.",
  "legal-guardian-confirmation-for-person": "You agree that you are a Parent or Legal Guardian for the person you are adding",
  "legal-guardian-agreement": "Yes, I am a Parent or Legal Guardian for {{firstName}} {{lastName}}.",
  "people-under-your-care": "People Under Your Care",
  "add-minor-or-pet-patient": "Add minor or pet patient",
  "your-allergies": "Your allergies",
  "confirmation": "Confirmation",
  "people-under-your-care-empty-page": "If you're managing prescriptions and appointments for other people or pets, having them all in one list is a convenient way to keep track of everyone's medications and schedules which can help avoid missed refills and appointments",
  "add-person-or-pet": "Add person or pet",
  "unable-to-submit-people-under-care-request": "Unable to submit people under care request",
  "relationship-to-me": "Relationship to me",
  "medical-info": "Medical Info",
  "none": "None",
  "notice": "Notice",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
  "thursday": "Thursday",
  "friday": "Friday",
  "saturday": "Saturday",
  "sunday": "Sunday",
  "meds": "Meds",
  "orders": "Orders",
  "reminders": "Reminders",
  "refill-details": "Refill Details",
  "success": "Success",
  "prescription-no": "Prescription No.",
  "name": "Name",
  "status": "Status",
  "submitted": "Submitted",
  "channel": "Channel",
  "delivery-method": "Delivery Method",
  "refill-comments": "Refill Comments",
  "patient-info": "Patient Info",
  "details": "Details",
  "phone": "Phone",
  "address": "Address",
  "email-text": "Email",
  "prescription": "Prescription",
  "patient": "Patient",
  "date-submitted": "Date submitted",
  "allergies": "Allergies: ",
  "medical-conditions": "Medical conditions: ",
  "refill-past-due": "Refill past due:",
  "refill-due": "Refill due:",
  "store": "Store",
  "app": "App",
  "caregiver-approval-agreement": "Caregiver Approval Agreement",
  "no-known-allergies": "No known allergies",
  "more-information": "More information",
  "monthly-prescriptions": "Would you like your prescriptions to be ready together at the same time each months?",
  "as-an-authorized-caregiver": "As an authorized Caregiver, you can:",
  "manage-prescriptions-digitally": "Manage {{firstName}}'s prescriptions digitally",
  "order-refills": "Order refills for {{firstName}}",
  "delivery": "Delivery",
  "mail": "Mail",
  "curbside": "Curbside",
  "relation-to-primary-user": "Relation to primary user",
  "would-like-easy-open-caps": "Would you like easy-open bottle caps?",
  "prefers-easy-open-caps": "Yes, I prefer easy-open caps",
  "prefers-safety-caps": "No, I prefer safety caps",
  "pick-up-prescriptions-for": "Pick up prescriptions for {{firstName}}",
  "discuss-care-with": "Discuss {{firstName}}'s care with a {{pharmacyName}} team member",
  "manage-health-and-clinic": "Manage {{firstName}}'s health and clinical service appointments",
  "access-validity-puc": "This access is valid until {{firstName}} turns 18 or you remove {{firstName}} from your list of people under your care.",
  "update-puc-list": "You can update your list by going to People Under My Care in Account Settings",
  "patient-record-created": "Patient Record created for {{firstName}} {{lastName}}",
  "medications-empty-state-title": "Medications",
  "phone-number-footer": "phone: {{phone}}",
  "fax": "fax: {{fax}}",
  "digital-pharmacist": "Digital Pharmacist",
  "pharmacy-website": "Pharmacy website and mobile app provided by",
  "medications-empty-state-description": "Adding your medications will make it easy to track and request refills on important prescriptions that help keep you healthy!",
  "medications-reminders-empty-state-title": "Medication Reminders",
  "medications-reminders-empty-state-description": "Medication reminders is a good way to stay on track of your meds which can help avoid unnecessary risk and serious illness",
  "add-meds": "Add Meds",
  "transfer-meds": "Transfer meds from another pharmacy",
  "pickup": "Pickup",
  "select-an-option": "Please select an option",
  "choose-from-the-list": "Please choose from the list of items before clicking the order button",
  "select-one-item": "Select at least one item",
  "show-more": "Show more",
  "street1": "Street 1",
  "street2": "Street 2",
  "gender-on-insurance": "Gender (as it appears on your insurance)",
  "preferred-language": "Preferred language",
  "notification-preferences": "Notification Preferences",
  "auto-voice-call": "Auto voice call",
  "edit-patient": "Edit Patient",
  "show-less": "Show less",
  "please-confirm-the-info-matches-prescription-label": "Please confirm that the information below matches what's on your prescription label",
  "register-confirmation-heading": "New account setup",
  "register-confirmation-body": "A new account setup link has been sent to your email. Please check your email and click the link provided.",
  "schedule": "Schedule",
  "orders-tab-empty-state-title": "Order history",
  "orders-tab-empty-state-description": "Placing a refill order is easy from the Meds tab! After you've placed an order, details and tracking will go here",
  "start-an-order": "Start an order",
  "show-locations": "Show locations",
  "first-name-edit-user": "First name",
  "last-name-edit-user": "Last name",
  "information": "Information",
  "find-time": "Find a time",
  "learn-more": "Learn more",
  "next-available": "Next available",
  "not-available": "Currently not available",
  "intro": "Intro",
  "enter-email": "Enter email",
  "create-password": "Create password",
  "register-confirmation": "Register confirmation",
  "register": "Register",
  "reset-link": "Reset link",
  "reset-successful": "Reset successful",
  "register-details": "Register details",
  "phone-verification": "Phone verification",
  "patient-pharmacy-stores": "Patient pharmacy stores",
  "add-prescription": "Add prescription",
  "refill-guest": "Refill guest",
  "home": "Home",
  "settings": "Settings",
  "store-location": "Store location",
  "expiring-soon": "Expiring soon",
  "minutes": "Minutes",
  "book-appointment": "Book Appointment",
  "upcoming": "Upcoming",
  "past": "Past",
  "patient-insurance": "Patient insurance",
  "barcode": "Barcode",
  "patient-preferences": "Patient preferences",
  "feedback-success": "Feedback success",
  "people-under-care": "People under care",
  "add-patient-under-care": "Add patient under care",
  "add-patient-under-care-info": "Add patient under care info",
  "add-patient-under-care-email": "Add patient under care email",
  "add-patient-under-care-confirmation": "Add patient under care confirmation",
  "patient-under-care-list": "Patient under care list",
  "automatic-prescription-flow": "Automatic prescription flow",
  "success-automatic-prescription-flow": "Success automatic prescription flow",
  "no-refills": "No refills",
  "expired": "Expired",
  "too-soon": "Too soon",
  "not-found": "Not found",
  "controlled": "Controlled",
  "narcotic": "Narcotic",
  "transferred": "Transferred",
  "discontinued": "Discontinued",
  "on-hold": "On hold",
  "deleted": "Deleted",
  "fillable": "Fillable",
  "partially-filled": "Partially filled",
  "out-of-stock": "Out of stock",
  "special-order": "Special order",
  "deceased": "Deceased",
  "rejected-reason-unknown": "Rejected reason unknown",
  "select-person": "Select the patient for this appointment. If you don't see them on the list, you will need to add them first.",
  "myself": "Myself",
  "confirm": "Confirm",
  "error-loading-appointments": "Error loading appointments",
  "appointment-scheduled": "appointment is scheduled",
  "booking-error": "Something went wrong when trying to book the appointment. Please try again.",
  "error-loading-slots": "Error loading appointment slots",
  "error-loading-patient-record": "Error loading the patient record",
  "error-booking-appointment": "Error booking the appointment",
  "when": "When",
  "who": "Who",
  "where": "Where",
  "care-notes": "Care notes",
  "reschedule": "Reschedule",
  "cancel-appointment": "Cancel appointment",
  "error-loading-appointment": "Error loading appointment",
  "error-loading-appointment-type": "Error loading appointment type",
  "refill-medications": "refill-medications",
  "refill-medical-infos": "refill-medical-infos",
  "refill-review": "refill-review",
  "refill-other-information": "refill-other-information",
  "refill-prescription-method": "refill-prescription-method",
  "refill-insurance-method": "refill-insurance-method",
  "refill-confirmation": "refill-confirmation",
  "refill-order-details": "refill-order-details",
  "upcoming-refill": "Upcoming refill",
  "good-morning": "Good morning",
  "send-message": "Send message",
  "good-afternoon": "Good afternoon",
  "good-evening": "Good evening",
  "you-are-chatting-with": "You are chatting with:",
  "new-message": "New Message",
  "no-messages": "No messages",
  "messages-have-a-question": "Have a question? Need to change an order? Send us a secure message.",
  "new-message-placeholder": "Message...",
  "general": "General",
  "billing": "Billing",
  "med-info": "Med info",
  "side-effects": "Side effects",
  "message-subject-label": "Select the subject that best fits your message:",
  "send": "Send",
  "reschedule-appointment": "Reschedule appointment",
  "appointment-rescheduled": "appointment is rescheduled",
  "error-rescheduling-appointment": "Error rescheduling the appointment",
  "cancel-appointment-confirmation": "Are you sure you would like to cancel this appointment?",
  "appointment-canceled": "appointment is canceled",
  "previously-scheduled": "Previously scheduled:",
  "cancel-note": "Note: Canceling this appointment will not remove it from any third-party calendars it has been added to.",
  "cancel-error": "Something went wrong when trying to cancel the appointment. Please try again.",
  "error-cancel-appointment": "Error canceling the appointment",
  "keep": "Keep",
  "of": "of",
  "tomorrow": "Tomorrow",
  "today": "Today",
  "canceled": "Canceled",
  "events": "events",
  "error-field-number": "Field must be a number",
  "no-appointments": "You currently have no appointments.\nSelect a service to get started.",
  "no-services": "There are no services available for booking.",
  "video-header": "Videos",
  "videos": "Videos",
  "videos-filter": "FILTER",
  "videos-clear": "Clear",
  "videos-all": "All",
  "videos-medication": "Medication",
  "videos-condition": "Condition",
  "videos-administration": "Administration",
  "videos-missing-medication-video": "Missing Medication Video",
  "videos-general-health": "General health",
  "videos-search": "Search",
  "videos-share": "Share",
  "videos-category": "Category",
  "videos-title": "Title",
  "videos-language-spanish": "View in Spanish",
  "videos-language-english": "View in English",
  "videos-language-french": "View in French",
  "videos-duration": "Duration",
  "videos-url": "URL",
  "copied-to-clipboard": "Copied to clipboard",
  "videos-med-guides": "Med Guides",
  "videos-transcript": "Transcript",
  "delete-confirmation-modal": "Are you sure you want to delete the {{itemType}}: {{itemName}}?",
  "service": "Service",
  "create-appointment": "Create an appointment",
  "order-refill": "Order refill",
  "limit-booking-frequency": "Limit booking frequency",
  "limit-booking-duration": "Limit total booking duration",
  "limit-future-bookings": "Limit future bookings",
  "before-event": "Before event",
  "after-event": "After event",
  "into-future": "into the future",
  "within-date-range": "Within a date range",
  "add-limit": "Add limit",
  "calendar-days": "calendar days",
  "business-days": "business days",
  "per-day": "Per day",
  "per-week": "Per week",
  "per-month": "Per month",
  "no-buffer-time": "No buffer time",
  "content-box-title": "Displaying tasks that match the following criteria:",
  "duration": "Duration",
  "time": "Time",
  "check-later": "Please check back later",
  "birthdate": "Birthdate",
  "actions": "Actions",
  "service-record-enabled-tooltip": "Mark service as unavailable for making appointments",
  "service-record-disabled-tooltip": "Enable making appointments for this service",
  "copy-link-button-tooltip": "Copy to clipboard",
  "edit-service-record-tooltip": "Edit service",
  "delete-service-record-tooltip": "Delete service",
  "selecting-all-pages": "Selecting all pages ({{count}} items)",
  "select-all-pages": "Select all pages",
  "selecting-current-page": "Selecting this page only ({{count}} items)",
  "select-current-page": "Select this page only",
  "unselect-all": "Unselect all",
  "others": "others",
  "reorder": "Reorder",
  "generic-bulk-confirmation-modal": "Are you sure you want to {{bulkAction}} {{count}} {{type}} {{changeType}}?",
  "task": "task",
  "bulk-assignee-confirmation-modal": "Select the assignee for {{count}} {{type}}.",
  "bulk-due-date-confirmation-modal": "Select the due date for {{count}} {{type}}.",
  "unresolved": "Unresolved",
  "resolved": "Resolved",
  "in_progress": "In Progress",
  "on_hold": "On hold",
  "task-resolve": "Tasks Resolve",
  "mark-resolved": "Mark as Resolved",
  "mark-in-progress": "Mark as In Progress",
  "mark-flagged": "Mark as Flagged/Unflagged",
  "mark-unresolved": "Mark as Not Started",
  "mark-on-hold": "Mark as On Hold",
  "change-assignee": "Change Assigned User",
  "change-due": `Change Due Date`,
  "task-in-progress": "Tasks In Progress",
  "task-flag": "Tasks Flag",
  "assignee": "Assignee",
  "not-started": "Not Started",
  "over-the-counter": "Over the counter",
  "delete-task": "Delete Task",
  "venue": "Venue",
  "private": "Private",
  "public": "Public",
  "your-pharmacy": "Your pharmacy",
  "edit-availability-record-tooltip": "Edit Availability",
  "delete-availability-record-tooltip": "Delete Availability",
  "availability": "Availability",
  "new-chat-message": "New Chat Message",
  "your-refill-is-ready": "Your refill is ready",
  "hi-your-refill-is-ready": "Hi{{firstName}}, your refill is ready to be picked up.",
  "patient-is-required": "Patient is required!",
  "subject-is-required": "Subject is required!",
  "message-is-required": "Message is required!",
  "message-characters-limit-five-thousand": "Message characters limit is 5000!",
  "find-template": "Find template",
  "to": "To",
  "subject": "Subject",
  "note-inbox-messages-should-be-clinical-in-nature": "Note: Inbox messages should be clinical in nature. Sending marketing messages through this platform could violate TCPA and FCC regulations. Do not request credit card information.",
  "all": "All",
  "unread": "Unread",
  "unread-by-user": "Unread by user",
  "includes-me": "Includes me",
  "created-by-me": "Created by me",
  "last-seven-days": "Last 7 days",
  "filters": "Filters",
  "clear": "Clear",
  "text-formatting": "Text formatting",
  "send-attachment": "Send Attachment",
  "type-your-message": "Type your message...",
  "mark-as-unread": "Mark as unread",
  "chat-messages": "Chat Messages",
  "find-patient": "Find patient",
  "new-subject": "New subject",
  "add-mobile-number-send-message": "Add a mobile number to send a message",
  "add-mobile-number": "Add mobile number",
  "newest": "Newest",
  "oldest": "Oldest",
  "a-z": "A-Z",
  "new-chat": "New chat",
  "inbox": "Inbox",
  "auto": "Auto",
  "previous-week": "Previous week",
  "current-week": "Current week",
  "next-week": "Next week",
  "secure-message": "Secure Message",
  "unable-to-process": "Unable to process your request",
  "try-again-later": "Please try again later. If you need immediate assistance, please contact your pharmacy.",
  "message-pharmacy": "Message pharmacy",
  "call-pharmacy": "Call pharmacy",
  "tasks-grid-no-results-title": "Your search criteria doesn't have any tasks in the current time-frame",
  "tasks-grid-no-results-subtitle": "We couldn't find any tasks in the current time-frame based on your view and filters. Use the Previous / Next buttons to navigate in time, or the Current week button to reset the view to default.",
  "get-directions": "Get directions",
  "explore-other-locations": "Explore other locations offering this service",
  "error-getting-locations": "Error getting locations",
  "zip-code-is-not-valid": "Zip code is not valid",
  "street-1-is-not-valid": "Street 1 is not valid",
  "street-2-is-not-valid": "Street 2 is not valid",
  "allergies-are-not-valid": "Allergies-are-not-valid, a correct example is all1,all2,all3...",
  "conditions-are-not-valid": "Conditions are not valid, a correct example is cond1,cond2,cond3...",
  "make-appointment": "Make an appointment",
  "find-service-at-location": "Find services at a different location",
  "get-more-stores": "Get more stores",
  "no-medication-guide": "Currently unavailable. If you have questions about this medication, contact your pharmacy.",
  "error-loading-patients-under-care": "Error loading patients under care",
  "add-new-patient": "Add new patient...",
  "after-adding-patient": "After adding a new patient, please return to Appointments and select your desired service.",
  "pending-requests": "Pending requests:",
  "add-calendar-event": "Add to calendar",
  "by-application": "By application",
  "by-email": "By email",
  "by-sms": "By SMS",
  "by-voice": "By voice",
  "error-loading-patient": "An error occurred while trying to get the patient. Please try again.",
  "in": "in",
  "ago": "ago",
  "month-short": "m",
  "hour-short": "h",
  "day-short": "d",
  "missing-important-patient-record-data": "The patient record is missing import data.",
  "google": "Google",
  "apple": "Apple",
  "outlook": "Outlook",
  "reset-filters": "Reset filters",
  "calendar": "Calendar",
  "appointment-panel": "Appointment Panel",
  "age": "Age",
  "confirmed": "Confirmed",
  "pending": "Pending",
  "rejected": "Rejected",
  "service-description": "Service description",
  "additional-info": "Additional info",
  "more": "More",
  "less": "Less",
  "month": "Month",
  "week": "Week",
  "day": "Day",
  "dont-see-service": "Don't see the service you need?",
  "service-category": "Service category",
  "team": 'Team',
  "personal": "Personal",
  "reschedule-note": "Note: Rescheduling this appointment will not remove it from any third-party calendars it has been added to.",
  "ordered":"Ordered",
  "characters-count": "Characters count: {{count}}",
  "maxim-count-attachments": "Maxim count of attachments is {{count}}.",
  "selecting-files-wrong": "Something went wrong selecting files.",
  "files-sending-error-try-again": "Something went wrong sending files. Please, try again.",
  "home-account-subheading": "Settings, insurance, help, etc",
  "home-medications-subheading": "Manage meds, and order refills",
  "home-messages-subheading": "Securely chat with the pharmacy",
  "home-appointments-subheading": "View or schedule clinical services",
  "home-puc-subheading": "Add a person...",
  "create-form-first": "Please create a form first",
  "pharmacy": "Town Pharmacy",
  "pharmacy-general-info": "General info",
  "pharmacy-edit-general-info": "Edit General info",
  "pharmacy-general-details": "Details",
  "pharmacy-company-name": "Company name",
  "pharmacy-company-admin": "Company admin",
  "pharmacy-ncpdp": "NCPDP",
  "pharmacy-npi": "NPI",
  "pharmacy-pms": "PMS",
  "pharmacy-facebook": "Facebook",
  "pharmacy-instagram": "Instagram",
  "pharmacy-twitter": "Twitter",
  "pharmacy-contact-info": "Contact info",
  "pharmacy-edit-contact-info": "Edit Contact info",
  "pharmacy-email-address": "Email address",
  "pharmacy-phone-number": "Phone number",
  "pharmacy-fax-number": "Fax number",
  "pharmacy-mailing-address": "Mailing address",
  "pharmacy-billing-address": "Billing address",
  "pharmacy-brand-name-length": "The name must not exceed 16 characters",
  "url-is-not-valid": "URL is not valid",
  "ok": "OK",
  "upload": "Upload",
  "replace": "Replace",
  "front-photo-missing": "Front image is missing!",
  "back-photo-missing": "Back image is missing!",
  "no-allergies-2": "No allergies",
  "easy-open-bottle-caps-confirm": "Would you like easy-open bottle caps?",
  "allergy-info":"Confirm that allergy info is up-to-date",
  "forms-fill-out":"Fill out {{formsCount}} required forms...",
  "health-info":"Review related health info",
  "insurance-card-reminder":"Bring insurance card",
  "pre-appointment-checklist":"Pre-appointment checklist",
  "error-updating-appointment": "Error updating the appointment",
  "message-body-is-required": "Message body is required",
  "verify-patient": "Verify patient",
  "verify-patient-warning": "Please verify this patient's name, date of birth, and phone number before disclosing any personal health information",
  "no-forms-required": "No forms were required for this appointment",
  "form-unavailable":"Form is no longer available",
  "form-not-started":"Form not started",
  "form-submitted": "Form has been submitted",
  "preparing-submission":"Preparing the submission",
  "download-will-begin":"Your download will begin shortly.",
  "delete-submission-confirmation":"Are you sure you want to delete this submission?",
  "not-entered":"Not entered",
  "submitted-on":"Submitted On",
  "download-pdf":"Download PDF",
  "submission-modal":"Submission Modal",
  "medical-information":"Medical information",
  "yes-easy-caps":"Yes, I prefer easy-open caps",
  "no-safety-caps" :"No, I prefer safety caps",
  "easy-open-bottle-caps-description":"Federal regulations require that most medications be packaged with child-resistant “safety” caps. Your choice of easy-open caps indicates your desire to have medications dispensed with non-safety caps.",
  "checklist-complete" : "Checklist completed",
  "checklist-incomplete": "Please complete the checklist",
  "service-status-updated":"Service status updated successfully",
  "error-loading-form": "Error loading form",
  "invalid-email-format": "Invalid email format",
  "close-confirmation-question": "Are you sure you want to exit?",
  "close-confirmation-text": "This form is required to schedule your appointment.",
  "close-confirmation-exit": "Exit",
  "close-confirmation-keep-appointment": "Keep appointment",
  "password-reset-subheading": "For the security and protection of your account, please create a new password.",
  "save-password": "Save password",
  "venue-in-person": "In Person",
  "venue-virtual": "Video",
  "venue-over-the-phone": "Phone",
  "get-the-app": "Get the app",
  "edit-reschedule-this-appointment": "Edit or reschedule this appointment",
  "cancel-this-appointment": "Cancel this appointment",
  "message-this-patient": "Message this patient",
  "contact-pharmacy-for-policy-info": "Please contact your local pharmacy for more information about their",
  "prescriptions-empty-state-paragraph-one":"Adding your medications will make it easy to track and request refills on important prescriptions that help keep you healthy!",
  "prescriptions-empty-state-paragraph-two":"To add your prescriptions now, tap the button below. If you would like to transfer your medications from another pharmacy, please tap the link above.",
  "prescriptions-empty-state-button-text":"Add meds",
  "otc-empty-state-paragraph-one":"Letting your pharmacy know about all the medications, vitamins and supplements you take can help them look out for possible drug interactions, keeping you safer and healthier!",
  "otc-empty-state-paragraph-two":"This includes any medications you use that don’t require a prescription",
  "otc-empty-state-button-text":"Add OTC meds",
  "viewing-medications-for":"Viewing medications for:",
  "error-occurred-title": "An unexpected error has occurred",
  "error-occurred-description": "If you need immediate assistance, please contact your pharmacy.",
  "contact-prescription-ready": "We will contact you when your prescription is ready",
  "task-type":"Type",
  "task-status": "Status",
  "task-assigned-to": "Assigned to",
  "task-priority": "Priority",
  "task-created-by": "Created by",
  "task-due-by": "Due by",
  "task-visibility": "Visibility",
  "last-submission":"Last submission",
  "no-submissions":"No forms has been submitted",
  "search-forms":"Search forms",
  "no-submissions-found":"No submissions matching the search found"
};

export default mapping;
